import React, { Fragment } from 'react';
import { ArrowRight } from './SVG';
import FeaturedExhibition from './FeaturedExhibtion';
import Reveal from './Reveal';

import cx from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

const FeaturedProjectCarousel = ({
	currentFilter,
	featuredExhibitionsTitle,
	featuredExhibitions = [],
}) => {
	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});
	return (
		<Reveal>
			<div
				className={cx(
					'featured__slider-wrapper reveal__slide reveal__delay--1 ',
					{
						active: currentFilter,
					}
				)}
			>
				{featuredExhibitions.length > 1 ? (
					<section className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg pr">
						<h2 className="sans--24 sans--48--md sans--64--lg">
							{featuredExhibitionsTitle || 'Featured Exhibitions'}
						</h2>
						<div
							ref={sliderRef}
							className="keen-slider featured__slider df pr mt2 mt5--md"
						>
							{featuredExhibitions.map(exhibition => {
								return (
									<div
										className="keen-slider__slide x"
										key={exhibition._key}
									>
										<FeaturedExhibition
											exhibition={exhibition}
										/>
									</div>
								);
							})}
						</div>
						{slider && (
							<Fragment>
								<button
									className="slider__button slider__button--prev pa"
									onClick={e =>
										e.stopPropagation() || slider.prev()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider flip" />
								</button>
								<button
									className="slider__button slider__button--next pa"
									onClick={e =>
										e.stopPropagation() || slider.next()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider" />
								</button>
							</Fragment>
						)}
					</section>
				) : (
					<section className="reveal__slide reveal__delay--1">
						<h2 className="sans--24 sans--48--md sans--64--lg">
							{featuredExhibitionsTitle || 'Featured Exhibitions'}
						</h2>
						<FeaturedExhibition
							exhibition={featuredExhibitions[0]}
						/>
					</section>
				)}
			</div>
		</Reveal>
	);
};

export default FeaturedProjectCarousel;
