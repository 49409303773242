import React, { useState, useEffect, Fragment } from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import Link from '../components/Link';
import FeaturedProjectCarousel from '../components/FeaturedProjectCarousel';
import FeaturedLinkTitle from '../components/FeaturedLinkTitle';

import { useNavigate } from '@reach/router';
import { SwitchTransition, Transition } from 'react-transition-group';
import InfiniteScrollComponent from '../components/InfiniteScrollComponent';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import sanityImage from '../util/sanityImage';
import scrollTo from 'magic-tricks/lib/scrollTo';
import cx from 'classnames';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const ExhibitionsIndex = ({ pageContext, location }) => {
	const {
		seo = {},
		featuredExhibitionsTitle,
		featuredExhibitions = [],
		listTitle,
		list = [],
		upcomingListTitle,
		upcomingList = [],
		pastListTitle,
		pastList = [],
		siteDescription,
		anatomyDescription,
		queueDescription,
	} = pageContext;

	const navigate = useNavigate();
	const { search } = location;

	const [currentFilter, setCurrentFilter] = useState(null);

	const [filter, setFilter] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const articles = document.querySelectorAll('.currentexhibitions .exhibitions__list article');
      const allHidden = Array.from(articles).every(article => article.style.display === 'none');

      const currentExhibitions = document.querySelector('.currentexhibitions');
      if (currentExhibitions) {
        if (allHidden) {
          currentExhibitions.classList.add('dni');
        } else {
          currentExhibitions.classList.remove('dni');
        }
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [filter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const articles = document.querySelectorAll('.upcomingexhibitions .exhibitions__list article');
      const allHidden = Array.from(articles).every(article => article.style.display === 'none');

      const upcomingExhibitions = document.querySelector('.upcomingexhibitions');
      if (upcomingExhibitions) {
        if (allHidden) {
          upcomingExhibitions.classList.add('dni');
        } else {
          upcomingExhibitions.classList.remove('dni');
        }
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [filter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const articles = document.querySelectorAll('.pastexhibitions .exhibitions__list article');
      const allHidden = Array.from(articles).every(article => article.style.display === 'none');

      const pastExhibitions = document.querySelector('.pastexhibitions');
      if (pastExhibitions) {
        if (allHidden) {
          pastExhibitions.classList.add('dni');
        } else {
          pastExhibitions.classList.remove('dni');
        }
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [filter]);

  useEffect(() => {
    const filterElement = document.querySelector('.location-filter');
    const dropdownElement = document.querySelector('.location-filter-dropdown');

    const handleMouseOver = () => {
      dropdownElement.style.display = 'list-item';
    };

    const handleMouseOut = () => {
      dropdownElement.style.display = 'none';
    };

    filterElement.addEventListener('mouseover', handleMouseOver);
    filterElement.addEventListener('mouseout', handleMouseOut);

    // Cleanup the event listeners on unmount
    return () => {
      filterElement.removeEventListener('mouseover', handleMouseOver);
      filterElement.removeEventListener('mouseout', handleMouseOut);
    };
  }, []);
	
	const navSection = (
		<nav className="psy--md projects__navigation z10">
			<section className="df fw grid-container contained bg--white bb--black">
				<button
					className={cx(
						'sans--14 sans--18--lg button--filter tl pr2 pt1 pb1',
						{
							active: currentFilter === null,
						}
					)}
					onClick={() => {
						navigate(`/exhibitions`);
						setCurrentFilter(null);
						setTimeout(() => {
							scrollTo(0 - 90);
						}, 200);
					}}
				>
					All
				</button>
				<button
					className={cx(
						'sans--14 sans--18--lg button--filter location-filter tl pr2 pt1 pb1',
						{
							active: currentFilter === 'exhibition',
						}
					)}
					onClick={() => {
						navigate(`?type=exhibition`);
						setCurrentFilter('exhibition');
						setTimeout(() => {
							scrollTo(0 - 90);
						}, 200);
					}}
				>
					Exhibitions
				</button>
				<button
					className={cx(
						'sans--14 sans--18--lg button--filter tl pr2 pt1 pb1',
						{
							active: currentFilter === 'anatomy',
						}
					)}
					onClick={() => {
						navigate(`?type=anatomy`);
						setCurrentFilter('anatomy');
						setTimeout(() => {
							scrollTo(0 - 90);
						}, 200);
					}}
				>
					Anatomy
				</button>
				<button
					className={cx(
						'sans--14 sans--18--lg button--filter tl pr2 pt1 pb1',
						{
							active: currentFilter === 'site',
						}
					)}
					onClick={() => {
						navigate(`?type=site`);
						setCurrentFilter('site');
						setTimeout(() => {
							scrollTo(0 - 90);
						}, 200);
					}}
				>
					SITE
				</button>
				<button
					className={cx(
						'sans--14 sans--18--lg button--filter tl pt1 pb1',
						{
							active: currentFilter === 'queue',
						}
					)}
					onClick={() => {
						navigate(`?type=queue`);
						setCurrentFilter('queue');
						setTimeout(() => {
							scrollTo(0 - 90);
						}, 200);
					}}
				>
					QUEUE
				</button>
			</section>
			<section className={cx(
						'df fw grid-container contained bg--white pb1 bl--black bb--black br--black location-filter-dropdown',
						{
							dni: currentFilter !== 'exhibition',
						}
					)}>
			        <button
					className={cx(
						'sans--14 sans--18--lg button--filter tl mt1 mr2',
						{
							active: filter === '',
						}
					)}
					onClick={() => setFilter("")}
					>All Locations</button>
			        <button
					className={cx(
						'sans--14 sans--18--lg button--filter tl mt1 mr2',
						{
							active: filter === 'the-shepherd',
						}
					)}
					onClick={() => setFilter("the-shepherd")}
					>The Shepherd</button>
			        <button
					className={cx(
						'sans--14 sans--18--lg button--filter tl mt1',
						{
							active: filter === 'downtown-detroit',
						}
					)}
					onClick={() => setFilter("downtown-detroit")}
					>Downtown Detroit</button>
			</section>
		</nav>
	);

	const unfilteredList = (
		<Fragment>
			{list && list.length > 0 && (
				<Reveal>
					<section
						className={cx(
							'grid-container contained mt5 mt10--md  mb5 mb10--md mb20--lg reveal__slide reveal__delay--1',
							{
								'mt20--lg':
									featuredExhibitions &&
									featuredExhibitions.length,
							}
						)}
					>
						<h2 className="sans--24 sans--48--md sans--64--lg">
							{listTitle || 'Current Exhibitions'}
						</h2>
						<div className="exhibitions__list row df fw mt2 mt5--lg">
							{list.map((item, index) => {
								const {
									_key,
									slug,
									title,
									artist,
									exhibitionLocation,
									exhibitionLocationOther,
									image,
									hero,
									startDate,
									endDate,
									linkType,
									subtitle,
								} = item;
								const picture = image || hero;

								let destination;

								switch (linkType) {
									case 'exhibition':
										destination = `/exhibitions/${slug}`;
										break;
									case 'artist':
										destination = `/artists/${slug}`;
										break;
									case 'site':
										destination = `/exhibitions/site-${slug}`;
										break;
									case 'anatomy':
										destination = `/exhibitions/anatomy-${slug}`;
										break;
									case 'queue':
										destination = `/exhibitions/queue-${slug}`;
										break;
									case 'alliance':
										destination = `/alliance/${slug}`;
										break;
									case 'project':
										destination = `/projects/${slug}`;
										break;
									case 'page':
										destination = `/${pageSlug}`;
										break;

									default:
										break;
								}

								return (
									<article
										className={cx('col c6--md', {
											mt5: index > 0,
											'mt0--md': index === 1,
											'mt10--md': index > 1,
										}, exhibitionLocation
										)}
										style={{ display: filter === "" || filter === exhibitionLocation ? "block" : "none" }}
									>
										<Link
											key={_key}
											url={destination}
											className="df fdc y"
										>
											<picture className="b--black image--tile">
												<source
													srcSet={sanityImage(
														picture.url,
														{
															w: 1200,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														picture.alt ||
														title ||
														artist ||
														'Library Street Collective'
													}
													className="db featured-link--image y"
													src={sanityImage(
														picture.url,
														{
															w: 1200,
														}
													)}
												/>
											</picture>
											<aside className="mt2 df jcb ais">
												<FeaturedLinkTitle
													linkType={linkType}
													title={title}
													artist={artist}
													exhibitionLocation={exhibitionLocation}
													exhibitionLocationOther={exhibitionLocationOther}
													endDate={endDate}
													startDate={startDate}
													subtitle={subtitle}
												/>
											</aside>
										</Link>
									</article>
								);
							})}
						</div>
					</section>
				</Reveal>
			)}

			{upcomingList && upcomingList.length > 0 && (
				<Reveal>
					<section
						className={cx(
							'grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg reveal__slide reveal__delay--1',
							{
								'mt20--lg': list && list.length,
							}
						)}
					>
						<h2 className="sans--24 sans--48--md sans--64--lg">
							{upcomingListTitle || 'Upcoming Exhibitions'}
						</h2>
						<div className="exhibitions__list row df fw mt2 mt5--lg">
							{upcomingList.map((item, index) => {
								const {
									_key,
									slug,
									title,
									artist,
									exhibitionLocation,
									exhibitionLocationOther,
									image,
									hero,
									startDate,
									endDate,
									linkType,
									subtitle,
								} = item;
								const picture = image || hero;

								let destination;

								switch (linkType) {
									case 'exhibition':
										destination = `/exhibitions/${slug}`;
										break;
									case 'artist':
										destination = `/artists/${slug}`;
										break;
									case 'site':
										destination = `/exhibitions/site-${slug}`;
										break;
									case 'anatomy':
										destination = `/exhibitions/anatomy-${slug}`;
										break;
									case 'queue':
										destination = `/exhibitions/queue-${slug}`;
										break;
									case 'alliance':
										destination = `/alliance/${slug}`;
										break;
									case 'project':
										destination = `/projects/${slug}`;
										break;
									case 'page':
										destination = `/${pageSlug}`;
										break;

									default:
										break;
								}

								return (
									<article
										className={cx('col c6--md', {
											mt5: index > 0,
											'mt0--md': index === 1,
											'mt10--md': index > 1,
										}, exhibitionLocation
										)}
										style={{ display: filter === "" || filter === exhibitionLocation ? "block" : "none" }}
									>
										<Link
											key={_key}
											url={destination}
											className="df fdc y"
										>
											<picture className="b--black image--tile">
												<source
													srcSet={sanityImage(
														picture.url,
														{
															w: 1200,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														picture.alt ||
														title ||
														artist ||
														'Library Street Collective'
													}
													className="db featured-link--image y"
													src={sanityImage(
														picture.url,
														{
															w: 1200,
														}
													)}
												/>
											</picture>
											<aside className="mt2 df jcb ais">
												<FeaturedLinkTitle
													linkType={linkType}
													title={title}
													artist={artist}
													exhibitionLocation={exhibitionLocation}
													exhibitionLocationOther={exhibitionLocationOther}
													endDate={endDate}
													startDate={startDate}
													subtitle={subtitle}
												/>
											</aside>
										</Link>
									</article>
								);
							})}
						</div>
					</section>
				</Reveal>
			)}

			{pastList && pastList.length > 0 && (
				<Reveal>
					<section className="grid-container contained mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg reveal__slide reveal__delay--1">
						<h2 className="sans--24 sans--36--md sans--48--lg">
							{pastListTitle || 'Past Exhibitions'}
						</h2>
						<div className="exhibitions__list row df fw mt2 mt5--lg">
							{pastList.map(
								(
									{
										_key,
										slug,
										title,
										artist,
										exhibitionLocation,
										exhibitionLocationOther,
										image,
										hero,
										startDate,
										endDate,
										linkType,
										subtitle,
									},
									index
								) => {
									const picture = image || hero;

									let destination;

									switch (linkType) {
										case 'exhibition':
											destination = `/exhibitions/${slug}`;
											break;
										case 'artist':
											destination = `/artists/${slug}`;
											break;
										case 'site':
											destination = `/exhibitions/site-${slug}`;
											break;
										case 'anatomy':
											destination = `/exhibitions/anatomy-${slug}`;
											break;
										case 'queue':
											destination = `/exhibitions/queue-${slug}`;
											break;
										case 'alliance':
											destination = `/alliance/${slug}`;
											break;
										case 'project':
											destination = `/projects/${slug}`;
											break;
										case 'page':
											destination = `/${pageSlug}`;
											break;

										default:
											break;
									}

									return (
										<article
											className={cx('col c6--md c4--lg', {
												mt5: index > 0,
												'mt0--md': index < 3,
												'mt10--md': index > 2,
											}, exhibitionLocation
										)}
										style={{ display: filter === "" || filter === exhibitionLocation ? "block" : "none" }}
										>
											<Link
												key={_key}
												url={destination}
												className="df fdc y"
											>
												<picture className="db b--black image--tile">
													<source
														srcSet={sanityImage(
															picture.url,
															{
																w: 1200,
															}
														)}
														media="(min-width: 600px)"
													/>
													<img
														alt={
															picture.alt ||
															title ||
															artist ||
															'Library Street Collective'
														}
														className="db featured-link--image y"
														src={sanityImage(
															picture.url,
															{
																w: 1200,
															}
														)}
													/>
												</picture>
												<aside className="mt2 df jcb ais">
													<FeaturedLinkTitle
														linkType={linkType}
														title={title}
														artist={artist}
														exhibitionLocation={exhibitionLocation}
														exhibitionLocationOther={exhibitionLocationOther}
														endDate={endDate}
														startDate={startDate}
														subtitle={subtitle}
													/>
												</aside>
											</Link>
										</article>
									);
								}
							)}
						</div>
					</section>
				</Reveal>
			)}
		</Fragment>
	);

	const filteredCurrent =
		(list && list.filter(item => item.linkType === currentFilter)) || [];
	const filteredUpcoming =
		(upcomingList &&
			upcomingList.filter(item => item.linkType === currentFilter)) ||
		[];
	const filteredPast =
		(pastList &&
			pastList.filter(item => item.linkType === currentFilter)) ||
		[];

	const filteredList = (
		<Fragment>
			<Reveal className="grid-container contained mt5 mt10--md mb5 mb10--md">
				<section className="reveal__slide reveal__delay--1">
					{(currentFilter === 'site' ||
						currentFilter === 'anatomy' ||
						currentFilter === 'queue') && (
						<div className="row align--center">
							<div className="col c10--md c9--lg c7--xl">
								<div className="sans--18 sans--24--md line-break rich-text ">
									{currentFilter == 'site' && (
										<BlockContent
											blocks={siteDescription}
											serializers={richText}
										/>
									)}
									{currentFilter == 'anatomy' && (
										<BlockContent
											blocks={anatomyDescription}
											serializers={richText}
										/>
									)}
									{currentFilter == 'queue' && (
										<BlockContent
											blocks={queueDescription}
											serializers={richText}
										/>
									)}
								</div>
							</div>
						</div>
					)}
					{filteredCurrent && filteredCurrent.length > 0 && (
						<div className="currentexhibitions mt5 mt10--md mt10--lg mb5 mb10--md mb20--lg">
							<h2 className="sans--18 sans--36--md sans--48--lg">
								{listTitle || 'Current Exhibitions'}
							</h2>
							<div className="exhibitions__list row df fw mt2 mt5--lg">
								{filteredCurrent.map((item, index) => {
									const {
										_key,
										slug,
										title,
										artist,
										exhibitionLocation,
										exhibitionLocationOther,
										image,
										hero,
										startDate,
										endDate,
										linkType,
										subtitle,
									} = item;

									const picture = image || hero;

									let destination;

									switch (linkType) {
										case 'exhibition':
											destination = `/exhibitions/${slug}`;
											break;
										case 'artist':
											destination = `/artists/${slug}`;
											break;
										case 'site':
											destination = `/exhibitions/site-${slug}`;
											break;
										case 'anatomy':
											destination = `/exhibitions/anatomy-${slug}`;
											break;
										case 'queue':
											destination = `/exhibitions/queue-${slug}`;
											break;
										case 'alliance':
											destination = `/alliance/${slug}`;
											break;
										case 'project':
											destination = `/projects/${slug}`;
											break;
										case 'page':
											destination = `/${pageSlug}`;
											break;

										default:
											break;
									}

									if (linkType === currentFilter) {
										return (
											<article
												className={cx('col c6--md', {
													mt5: index > 0,
													'mt0--md': index === 1,
													'mt10--md': index > 1,
												}, exhibitionLocation
										)}
										style={{ display: filter === "" || filter === exhibitionLocation ? "block" : "none" }}
											>
												<Link
													key={_key}
													url={destination}
													className="df fdc y"
												>
													<picture className="db b--black image--tile x">
														<source
															srcSet={`${picture.url}?w=800&auto=format`}
															media="(min-width: 600px)"
														/>
														<img
															src={picture.url}
															className="db featured-link--image y"
															alt={
																picture.alt ||
																title ||
																artist ||
																'Library Street Collective'
															}
														/>
													</picture>
													<aside className="mt2 df jcb ais">
														<FeaturedLinkTitle
															linkType={linkType}
															title={title}
															artist={artist}
															exhibitionLocation={exhibitionLocation}
															exhibitionLocationOther={exhibitionLocationOther}
															endDate={endDate}
															startDate={
																startDate
															}
															subtitle={subtitle}
														/>
													</aside>
												</Link>
											</article>
										);
									}
								})}
							</div>
						</div>
					)}
				</section>
			</Reveal>

			{filteredUpcoming && filteredUpcoming.length > 0 && (
				<Reveal className="upcomingexhibitions grid-container contained mt5 mt10--md mt10--lg mb5 mb10--md mb20--lg">
					<section className="reveal__slide reveal__delay--1">
						<h2 className="sans--18 sans--36--md sans--48--lg">
							{upcomingListTitle || 'Upcoming Exhibitions'}
						</h2>
						<div className="exhibitions__list row df fw mt2 mt5--lg">
							{filteredUpcoming.map((item, index) => {
								const {
									_key,
									slug,
									title,
									artist,
									exhibitionLocation,
									exhibitionLocationOther,
									image,
									hero,
									startDate,
									endDate,
									linkType,
									subtitle,
								} = item;
								const picture = image || hero;

								let destination;

								switch (linkType) {
									case 'exhibition':
										destination = `/exhibitions/${slug}`;
										break;
									case 'artist':
										destination = `/artists/${slug}`;
										break;
									case 'site':
										destination = `/exhibitions/site-${slug}`;
										break;
									case 'anatomy':
										destination = `/exhibitions/anatomy-${slug}`;
										break;
									case 'queue':
										destination = `/exhibitions/queue-${slug}`;
										break;
									case 'alliance':
										destination = `/alliance/${slug}`;
										break;
									case 'project':
										destination = `/projects/${slug}`;
										break;
									case 'page':
										destination = `/${pageSlug}`;
										break;

									default:
										break;
								}

								if (linkType === currentFilter) {
									return (
										<article
											className={cx('col c6--md', {
												mt5: index > 0,
												'mt0--md': index === 1,
												'mt10--md': index > 1,
											}, exhibitionLocation
										)}
										style={{ display: filter === "" || filter === exhibitionLocation ? "block" : "none" }}
										>
											<Link
												key={_key}
												url={destination}
												className="df fdc y"
											>
												<picture className="db b--black image--tile x">
													<source
														srcSet={`${picture.url}?w=800&auto=format`}
														media="(min-width: 600px)"
													/>
													<img
														src={picture.url}
														className="db featured-link--image y"
														alt={
															picture.alt ||
															title ||
															artist ||
															'Library Street Collective'
														}
													/>
												</picture>
												<aside className="mt2 df jcb ais">
													<FeaturedLinkTitle
														linkType={linkType}
														title={title}
														artist={artist}
														exhibitionLocation={exhibitionLocation}
														exhibitionLocationOther={exhibitionLocationOther}
														endDate={endDate}
														startDate={startDate}
														subtitle={subtitle}
													/>
												</aside>
											</Link>
										</article>
									);
								}
							})}
						</div>
					</section>
				</Reveal>
			)}
			{filteredPast && filteredPast.length > 0 && (
				<Reveal className="pastexhibitions grid-container contained mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg">
					<section className="reveal__slide reveal__delay--1">
						<h2 className="sans--18 sans--36--md sans--48--lg">
							{pastListTitle || 'Past Exhibitions'}
						</h2>
						<div className="exhibitions__list row df fw mt2 mt5--lg">
							{filteredPast.map((item, index) => {
								const {
									_key,
									slug,
									title,
									artist,
									exhibitionLocation,
									exhibitionLocationOther,
									image,
									hero,
									startDate,
									endDate,
									linkType,
									subtitle,
								} = item;
								const picture = image || hero;

								let destination;

								switch (linkType) {
									case 'exhibition':
										destination = `/exhibitions/${slug}`;
										break;
									case 'artist':
										destination = `/artists/${slug}`;
										break;
									case 'site':
										destination = `/exhibitions/site-${slug}`;
										break;
									case 'anatomy':
										destination = `/exhibitions/anatomy-${slug}`;
										break;
									case 'queue':
										destination = `/exhibitions/queue-${slug}`;
										break;
									case 'alliance':
										destination = `/alliance/${slug}`;
										break;
									case 'project':
										destination = `/projects/${slug}`;
										break;
									case 'page':
										destination = `/${pageSlug}`;
										break;

									default:
										break;
								}

								if (linkType === currentFilter) {
									return (
										<article
											className={cx('col c6--md c4--lg mb5', {
												ssmt5: index > 0,
												'ssmt10--md': index < 3,
												'ssmt10--md': index > 2,
											}, exhibitionLocation
										)}
										style={{ display: filter === "" || filter === exhibitionLocation ? "block" : "none" }}
										>
											<Link
												key={_key}
												url={destination}
												className="df fdc y"
											>
												<picture className="db b--black image--tile x">
													<source
														srcSet={`${picture.url}?w=800&auto=format`}
														media="(min-width: 600px)"
													/>
													<img
														src={picture.url}
														className="db featured-link--image y"
														alt={
															picture.alt ||
															title ||
															artist ||
															'Library Street Collective'
														}
													/>
												</picture>
												<aside className="mt2 df jcb ais">
													<FeaturedLinkTitle
														linkType={linkType}
														title={title}
														artist={artist}
														exhibitionLocation={exhibitionLocation}
														exhibitionLocationOther={exhibitionLocationOther}
														endDate={endDate}
														startDate={startDate}
														subtitle={subtitle}
													/>
												</aside>
											</Link>
										</article>
									);
								}
							})}
						</div>
					</section>
				</Reveal>
			)}
		</Fragment>
	);

	const metaTitle =
		seo?.metaTitle || 'Exhibitions | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'Exhibitions | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'Exhibitions | Library Street Collective';

	useEffect(() => {
		const filter = search.split('?type=')[1];
		filter && setCurrentFilter(decodeURI(filter));

		return () => {
			setCurrentFilter(null);
		};
	}, [search]);

	return (
		<Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<h1 className="hidden-title">
				Exhibitions | Library Street Collective
			</h1>
			<Reveal>
				<section className="reveal__slide reveal__delay--1">
					{navSection}
					<SwitchTransition>
						<Transition
							key={currentFilter}
							mountOnEnter
							unmountOnExit
							appear
							timeout={TRANSITION_DURATION}
						>
							{status => (
								<div
									style={{
										...TRANSITION_STYLES.default,
										...TRANSITION_STYLES[status],
									}}
								>
									{featuredExhibitions &&
										currentFilter === null &&
										featuredExhibitions.length > 0 && (
											<FeaturedProjectCarousel
												currentFilter={currentFilter}
												featuredExhibitions={
													featuredExhibitions
												}
												featuredExhibitionsTitle={
													featuredExhibitionsTitle
												}
											/>
										)}

									{currentFilter === null && unfilteredList}
									{currentFilter && filteredList}
								</div>
							)}
						</Transition>
					</SwitchTransition>
				</section>
			</Reveal>
		</Fragment>
	);
};

export default ExhibitionsIndex;
