import React from 'react';
import Link from './Link';

import cx from 'classnames';
import sanityImage from '../util/sanityImage';

const FeaturedExhibition = ({ exhibition }) => {
	const {
		_type: linkType,
		slug,
		title,
		artist,
		exhibitionLocation,
		exhibitionLocationOther,
		startDate,
		endDate,
		image,
		hero,
	} = exhibition;

	const picture = image || hero;

	return (
		<Link className="df fdc jcb y x" url={slug}>
			<picture className="fa b--black">
				<source
					srcSet={sanityImage(picture.url, { w: 2000 })}
					media="(min-width: 1400px)"
				/>
				<source
					srcSet={sanityImage(picture.url, { w: 1600 })}
					media="(min-width: 1200px)"
				/>
				<source
					srcSet={sanityImage(picture.url, { w: 1400 })}
					media="(min-width: 1000px)"
				/>
				<source
					srcSet={sanityImage(picture.url, { w: 1200 })}
					media="(min-width: 600px)"
				/>
				<img
					alt={picture.alt || title || 'Image courtesy of the artist'}
					className="db db featured-link--image y"
					src={sanityImage(picture.url, { w: 1200 })}
				/>
			</picture>
			<aside className="mt2 df jcb ais">
				{linkType === 'exhibition' && (
					<div>
						<p className="sans--24 sans--36--md">{artist}</p>
						<p className="sans--14 sans--18">
							{title}
							{exhibitionLocationOther && (
								<React.Fragment>
									<br />
									<span className="sans--14 sans--18--md">
										Location: {exhibitionLocationOther}
									</span>
								</React.Fragment>
							)}
							{exhibitionLocation && !exhibitionLocationOther && (
								<React.Fragment>
									<br />
									<span className="sans--14 sans--18--md">
										{exhibitionLocation === 'downtown-detroit' && 'Location: 1260 Library Street, Detroit'}
										{exhibitionLocation === 'the-shepherd' && 'Location: the Shepherd'}
									</span>
								</React.Fragment>
							)}
							{endDate && startDate && (
								<React.Fragment>
									<br />
									<span className="sans--14 sans--18--md">
										({startDate} — {endDate})
									</span>
								</React.Fragment>
							)}
						</p>
					</div>
				)}
				{(linkType === 'site' ||
					linkType === 'anatomy' ||
					linkType === 'project' ||
					linkType === 'queue') && (
					<div>
						<p className="sans--24 sans--36--md">{title}</p>
						<p className="sans--14 sans--18--md">
							{linkType === 'site' && 'SITE: '}
							{linkType === 'anatomy' && 'Anatomy: '}
							{linkType === 'project' && artist}
							{linkType === 'queue' && 'QUEUE:'}
						</p>
					</div>
				)}

				<p className="tile uppercase sans--14">
					{linkType === 'exhibition' && 'Exhibition'}
					{linkType === 'site' && 'SITE'}
					{linkType === 'project' && 'Project'}
					{linkType === 'anatomy' && 'Anatomy'}
					{linkType === 'queue' && 'QUEUE'}
					{linkType === 'alliance' && 'Alliance'}
					{linkType === 'artist' && 'Artist'}
				</p>
			</aside>
		</Link>
	);
};
export default FeaturedExhibition;
